// import { Typography, TypographyTypeMap, useTheme } from "@mui/material";
// import { OverridableComponent } from "@mui/material/OverridableComponent";
import { createStyles, createTheme, makeStyles, Theme, Typography, TypographyTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import classNames from "classnames";
import React from "react";

// DataSpray theme: https://github.com/datasprayio/dataspray/blob/master/dataspray-site/src/pages/_app.tsx
export const themeDataSpray: Theme = createTheme({
  palette: {
    primary: {
      main: '#388e3c',
    },
    secondary: {
      main: '#f06292',
    },
  },
});

const useStyles = makeStyles((theme: Theme) => createStyles({
  extraStyles: {
    background: (props: React.ComponentPropsWithoutRef<typeof Typography>) => `linear-gradient(to right, ${props.color === 'primary' ? themeDataSpray.palette.primary.light : themeDataSpray.palette.secondary.light}, ${props.color === 'primary' ? themeDataSpray.palette.primary.dark : themeDataSpray.palette.secondary.dark})`,
    WebkitBackgroundClip: props => 'text', // Has to be with the same style class as 'background' so we need to pass in a function here
    WebkitTextFillColor: 'transparent',
  },
}));

// Borrowed from DataSpray: https://github.com/datasprayio/dataspray/blob/master/dataspray-site/src/landing/GradientTypography.tsx
const GradientTypography: OverridableComponent<TypographyTypeMap> = (props: React.ComponentPropsWithoutRef<typeof Typography>) => {
  const cs = useStyles(props);

  return (
    <Typography
      component='span'
      {...props}
      className={classNames(
        props.className,
        cs.extraStyles,
      )}
    />
  )
}

export default GradientTypography;
