import { createStyles, Link as MuiLink, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paragraph: {
    margin: theme.spacing(2, 0),
  },
  page: {
    textAlign: 'left',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none!important',
    borderBottom: '1px dashed #d0d0d0',
    '&:hover': {
      borderBottomStyle: 'solid',
      borderColor: 'black',
    },
  },
}));

export const CareersWrapper = (props: { title: string, children: any }) => {
  const classes = useStyles();
  return (
    <div className={classes.page}>
      <Typography variant='h4' component='h1'>{props.title}</Typography>
      <Typography variant='body1' className={classes.paragraph}>
        We are excited for you to join our team at Smotana. We are a foreign-invested company located in Ulaanbaatar working on startup projects for the global market.
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        Basic requirements:
      <ul>
          <li>Located in Ulaanbaatar, Mongolia.</li>
          <li>Fluent in English language.</li>
        </ul>
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        We bring our experience from Silicon Valley to our company culture. We value our employees by offering competitive salaries and providing you with opportunities to continue growing in your career. We value the work you complete, not the amount of hours you work. When you do decide to part ways in the future, there are no hard-feelings and we hope you gained valuable experience working for us.
      </Typography>
      {props.children}
      <Typography variant='body1' className={classes.paragraph}>
        At Smotana, we rely on a range of backgrounds, experiences, and ideas. We value diversity, and we’re proud to be an inclusive, equal opportunity workplace.
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        Please send your resume to <MuiLink className={classes.link} href="mailto:careers@smotana.com" color="inherit">careers@smotana.com</MuiLink>.
      </Typography>
    </div>
  );
};

export const EngineerToolsAndTechnologies = () => {
  const classes = useStyles();
  return (
    <Typography variant='body1' className={classes.paragraph}>
      Some of the tools, libraries and technologies you will use and learn:
      <ul>
        <li>Frontend: Isomorphic Javascript, Typescript, ReactJS, Redux, NodeJS</li>
        <li>Backend: OpenAPI, Java, Tomcat, Guice, Guava, Protobuf, KillBill, Stripe</li>
        <li>Database: DynamoDB, MySQL (Aurora), Redis, Zookeeper</li>
        <li>Cloud infrastructure on AWS: EC2, Route53, S3, DynamoDB, RDS, ElasticSearch, CloudFront, VPC, IAM, SES</li>
        <li>Security: Best practices (Authentication, networking, system hardening, PII management), Anti-Spam (Detection, Rate Limits, Captcha)</li>
        <li>SEO, SEM: Google Search, Google Ads, Google Analytics, Blog Marketing</li>
        <li>UI/UX: Design, A/B Testing, MaterialUI</li>
        <li>Environment: GitLab, Trello, Google Workspace</li>
      </ul>
    </Typography>
  );
};

export const SeniorSoftwareEngineer = () => {
  const classes = useStyles();
  return (
    <CareersWrapper title='Senior Software Engineer'>
      <Typography variant='body1' className={classes.paragraph}>
        As a Senior Software Engineer, you are spearheading the Architecture and Design of our infrastructure. You are a technical leader to your peers managing best-practices and quality of our code-base.
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        What you will do:
        <ul>
          <li>Technical architecture of existing and upcoming products</li>
          <li>Design with scalability and security in mind</li>
          <li>Maintain and improve existing code, lead by example</li>
          <li>Take ownership of production environments, build systems the right way</li>
          <li>Take part in entrepreneurship: research new products with us</li>
        </ul>
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        Role requirements:
        <ul>
          <li>Computer Science, equivalent degree, or equivalent experience</li>
          <li>5+ years of experience</li>
          <li>On-call rotation</li>
        </ul>
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        Must be an expert in one or more of the following areas:
        <ul>
          <li>Architecture and design of scalable and fault-tolerant systems</li>
          <li>Software development skills with proper planning, estimation, maintainable code, testing, code reviews.</li>
          <li>Backend development preferrably in Java, Tomcat</li>
          <li>Frontend development preferrably in Typescript, NodeJS</li>
          <li>System administration: Linux, networking, security hardening</li>
        </ul>
      </Typography>
      <EngineerToolsAndTechnologies />
    </CareersWrapper>
  );
};

export const SoftwareEngineer = () => {
  const classes = useStyles();
  return (
    <CareersWrapper title='Software Engineer'>
      <Typography variant='body1' className={classes.paragraph}>
        As a Software Engineer, you will be working with talented peers on exciting products and services. You will use and learn leading technologies with best-practices.
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        What you will do:
        <ul>
          <li>Maintain and improve existing code</li>
          <li>Write tests for your code, perform code reviews</li>
          <li>Deploy your changes to staging and production environments</li>
          <li>Research libraries and best-practices for upcoming features</li>
          <li>Debug and investigate issues in production</li>
        </ul>
      </Typography>
      <EngineerToolsAndTechnologies />
    </CareersWrapper>
  );
};

export const InboundMarketingSpecialist = () => {
  const classes = useStyles();
  return (
    <CareersWrapper title='Inbound Marketing Specialist'>
      <Typography variant='body1' className={classes.paragraph}>
        As an Inbound Marketing Specialist, you will be leading the marketing strategy for our current and upcoming products. Your goal is to narrow down the right market for our products and attract customers through various channels.
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        What you will do:
        <ul>
          <li>Research Product/Market fit</li>
          <li>Landing page content (SEO)</li>
          <li>Product Onboarding (UI/UX)</li>
          <li>Blog Marketing</li>
          <li>Product video promo</li>
          <li>Online Advertisement (SEM)</li>
          <li>Product pricing strategy</li>
          <li>Social platforms (SMO)</li>
        </ul>
      </Typography>
      <Typography variant='body1' className={classes.paragraph}>
        Role requirements:
        <ul>
          <li>Digital Marketing degree, equivalent degree, or equivalent experience</li>
          <li>2+ years of digital marketing experience preferrably in SAAS</li>
          <li>Fluent English writing skills</li>
        </ul>
      </Typography>
    </CareersWrapper>
  );
};

export const careers: Array<{
  title: string;
  slug: string;
  render: () => React.ReactNode;
}> = [
    { title: 'Senior Software Engineer', slug: 'senior-software-engineer', render: () => <SeniorSoftwareEngineer /> },
    { title: 'Software Engineer', slug: 'software-engineer', render: () => <SoftwareEngineer /> },
    { title: 'Inbound Marketing Specialist', slug: 'inbound-marketing-specialist', render: () => <InboundMarketingSpecialist /> },
  ];
