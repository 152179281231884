// SPDX-FileCopyrightText: 2019-2021 Matus Faro <matus@smotana.com>
// SPDX-License-Identifier: AGPL-3.0-only
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: (props: Props) => !props.suppressMargins ? theme.spacing(1) : undefined,
  },
  image: {
    marginRight: 8,
  },
  clear: {
    color: theme.palette.text.primary,
  },
  flask: {
    color: '#218774',
  },
});

interface Props {
  suppressMargins?: boolean;
  suppressName?: boolean;
  scale?: number;
}
class ClearFlaskLogo extends Component<Props & WithStyles<typeof styles, true>> {
  render() {
    const scale = this.props.scale || 1;
    var logo = (
      <div className={this.props.classes.container} style={{
        fontSize: '1.4rem',
      }}>
        <img
          src='/img/clearflask-logo.png'
          alt=''
          className={this.props.classes.image}
          width={32}
          height={32}
        />
        {!this.props.suppressName && (
          <>
            <span className={this.props.classes.clear}>Clear</span>
            <span className={this.props.classes.flask}>Flask</span>
          </>
        )}
      </div>
    );

    return logo;
  }
}

export default withStyles(styles, { withTheme: true })(ClearFlaskLogo);
